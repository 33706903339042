<template>
  <div>
    <!-- BREADCRUMBS -->
    <custom-breadcrumb
      :pageTitle="$t('Dreamers')"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>
    
    <!-- group reassign modal -->
    <b-modal
      id="reassign-modal"
      ref="reassign-modal"
      :title="$t('forms.reassign_group')"
      ok-only
      :ok-title="$t('forms.submit')"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkReassignGroup"

      no-close-on-backdrop
    >
      <b-card-text>
        <h5>{{$t('forms.reassign_group_description')}}</h5>
        {{$t('forms.reassign_group_disclaimer')}}
      </b-card-text>
      <form
        ref="reassignform"
        @submit.stop.prevent="handleReassignGroup"
      >
        <b-form-group
          :state="reassignState"
          :label="$t('fields.group')"
          label-for="group"
          :invalid-feedback="$t('forms.this_field_is_required')"
        >
          <v-select
              v-model="group_to_reassign"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="groups"
              required
            />
        </b-form-group>
      </form>
    </b-modal>

    <modal-personalize
      :isGroup="false"
      :selected_data="selDreamersData"
    />
    <modal-content-restriction
      :isGroup="false"
      :selected_data="selDreamersData"
    />

    <modal-children-passwords
      :isGroup="false"
      :selected_data="selDreamersData"
    />

    <b-sidebar v-if="sidebar_lp_dreamer_id"
      :key="sidebar_lp_dreamer_id"
      :id="'sidebar-right-' + sidebar_lp_dreamer_id"
      bg-variant="white"
      right
      backdrop
      shadow
      @hidden="hideLPSidebar"
      :visible="showSidebar"
      style="z-index: 2000"
    >
      <sidebar-assigned-routes @hide="hideLPSidebar" :dreamerId="sidebar_lp_dreamer_id"/>
    </b-sidebar>
    
    <!-- delete all modal -->
    <b-modal
      id="delete-all"
      ref="delete-all"
      :title="$t('forms.delete_all.dreamers')"
      ok-only
      :ok-title="$t('forms.submit')"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkDeleteAll"

      no-close-on-backdrop
    >
      <b-card-text>
        <h5>{{$t('forms.delete_all.description')}}</h5>
        {{$t('forms.delete_all.disclaimer')}}
        <p>
          {{$t('forms.delete_all.enter_confirmation_text')}}: <b>{{ stablished_confirmation_text}}</b>
        </p>
      </b-card-text>
      <form
        ref="deleteallform"
        @submit.stop.prevent="handleDeleteAll"
      >
        <b-form-group
          :state="deleteAllState"
          :label="$t('forms.delete_all.confirmation_text')"
          label-for="delete_all"
        >
          <!-- invalid-feedback="Confirmation text is required" -->
          <b-form-input
            id="delete_all"
            v-model="confirmation_text"
            :state="deleteAllState"
            required
          />

          <span class="mt-1" 
           style="color:#ea5455; font-size: 0.857rem; width:100%;"
           v-if="confirmation_text && validate_confirmation_text"
          >{{$t('forms.delete_all.confirmation_text_error')}}</span>
        </b-form-group>
      </form>
    </b-modal>
    
    <!-- ADD DREAMER -->
    <add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :school_id="school_id"
      :groups="groups"
      :users="users"
      @refetch-data="getData"
    />

    <!-- IMPORT WIZARD  -->
    <import-wizard
      v-if="showImportModal"
      @close="showImportModal = false"
      :school_id="$route.params.id"
    ></import-wizard>

    <!-- showDeleteByExcel -->
    <delete-by-excel
      v-if="showDeleteByExcel"
      @close="showDeleteByExcel = false"
      @deletedLicenses="deletedLicenses"
      :school_id="school_id"
      @reset-data="getData"
    ></delete-by-excel>
    
    <b-card no-body v-else>
      <!-- FILTROS -->
      <filters
        ref="filter"
        context="dreamers"
        :school_id="school_id"
        :status-options="statusOptions"
        :groups-options="groupsOptions"
        @set-data="setData"
        @set-loading-data="setloadingData"
      /> 

      <!-- TABLA -->
      <paginated-table
        :data="dreamers"
        ability="SchoolDreamers"
        link='school-dreamers'
        @manage-selected-rows="manageSelectedRows"
        @delete="deleteModel"
        @recover="recoverModel"
        :canDownload="false"
        :loading_data="loading_data"
        :school_id="school_id"

        :buttonsOptions="!showImportModal && !showDeleteByExcel ? buttonsOptions: importOptions"
        @close="showImportModal = false; showDeleteByExcel=false"
        @create="isAddNewSidebarActive = true"
        @import="showImportModal = true"
        @reassignGroup="showModalReassignGroup"
        @deleteSelection="deleteSelection"
        @deleteAll="showModalDeleteAll"
        @deleteByExcel="showDeleteByExcel = true"
        @customizeDreamers="$bvModal.show('modal-personalize')"
        @contentRestriction="$bvModal.show('modal-content-restriction')"
        @assignPasswords="$bvModal.show('modal-assign-pass')"
        @mostrar-sidebar="showLPSidebar"
      ></paginated-table>
    </b-card>
  </div>
</template>

<script>
import SidebarAssignedRoutes from '@/views/learning-paths/components/SidebarAssignedRoutes.vue'

import { deleteAllSchoolDreamer, schoolDreamerReasign, deleteSelectedSchoolDreamer, deleteSchoolDreamer, recoverSchoolDreamer, getSchoolDreamers } from '@/api/routes'
import {
  BCard, BRow, BCol, BCardText,
  BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,
  BSidebar
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import Filters from '@/views/sl-components/Filters.vue'
import AddNew from './AddNew.vue'
import ImportWizard from '@/views/sl-components/ImportExcelWizard.vue'
import DeleteByExcel from '@/views/schools/dreamers/bulkDelete/DeleteByExcel.vue'
import ModalPersonalize from '@/views/modals/ModalPersonalize.vue'
import ModalContentRestriction from '@/views/modals/ModalContentRestriction.vue'
import ModalChildrenPasswords from '@/views/modals/ModalChildrenPasswords.vue'


export default {
  components:{
    BCard, BRow, BCol,BCardText,
    BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,
    vSelect,

    CustomBreadcrumb,
    PaginatedTable,
    Filters,
    ImportWizard,
    AddNew,
    DeleteByExcel,
    ModalPersonalize,
    ModalContentRestriction,
    ModalChildrenPasswords,
    BSidebar,
    SidebarAssignedRoutes,
  },
  data(){
    return {
      loading_data: false,

      school_id: '',
      showImportModal: false,
      isAddNewSidebarActive: false,
      selected: [],
      dreamers: [],
      groups:[],
      users:[],
      groupsOptions: [],

      group_to_reassign: '',
      reassignState: null,
      confirmation_text:'',
      deleteAllState:null,

      showDeleteByExcel: false,

      school: {},
      showSidebar:false,
      sidebar_lp_dreamer_id: null,
      school_admin_max_children: 0
    }
  },
  computed: {
    selDreamersData(){
      //let d = this.selected_dreamers.slice(0, 5).map(x => this.dreamers.filter(e=> e.id == x))
      let d = this.selected.map(x => this.dreamers.filter(e=> e.id == x.id))
      let clean_d = d.map(x=>{ return{'id': x[0].id, 'name': x[0].name, 'avatar': x[0].avatar, 'owner': x[0].owner}})
      let only_ids = d.map(dreamer => dreamer[0].id)
      let data = {
        ids: only_ids,
        info: clean_d
      }
      return data
    },
    breadcrumb(){
      if(this.$store.getters['user/isSchool']){
            this.school_id = this.$store.getters['user/isSchool'].id
            return [
              {
                text: this.$t('Schools'),
              },
              {
                text: this.school.name
              },
              {
                text: this.$t('Dreamers'),
                active: true,
              },
            ]
          } else {
            this.school_id = this.$route.params.id;

            return [
              {
                text: this.$t('Schools'),
                to: 'schools'
              },
              {
                to: 'school-view',
                params: {
                  id: this.$route.params.id,
                },
                text: this.school.name
              },
              {
                text: this.$t('Dreamers'),
                active: true,
              },
            ]
          }
    },
    importOptions(){
      return [
        {
          action: "close",
          icon: "XIcon",
          name: this.$t('import_wizard.go_back_to_list')
        },
      ]
    },
    stablished_confirmation_text(){
      return this.$t('forms.delete_all.stablished_confirmation_text')
    },
    validate_confirmation_text(){
      let valid_text = true
        if(this.confirmation_text == this.stablished_confirmation_text){
          valid_text = false
      }
      this.deleteAllState = !valid_text
      return valid_text
    },
    statusOptions(){
      let role = this.$store.getters['user/getUserData'].role
      if(role == 'admin' || role == 'support'){
        return [
              { value: 'active', label: this.$t('fields.active') },
              { value: 'inactive', label: this.$t('fields.inactive') },
            ]
      } else {
        return []
      }
    },
    buttonsOptions(){
      let opt = []
      /* si el max_children de la licencia administrativa del cole es 0 (ilimitado)
      o si es mayor que los dreamers que posee, permitir crear dreamer */

      let can_create_new_dreamers = false
      if(this.school_admin_max_children == 0 || this.school_admin_max_children > this.dreamers.length){
        can_create_new_dreamers = true
      }
      opt.push({
        action: 'create',
        icon: "PlusIcon",
        variant: !can_create_new_dreamers? "secondary":"success",
        name: this.$t('forms.add_model', {model: this.$t('models.dreamer')}),
        disabled: !can_create_new_dreamers,
        msg:!can_create_new_dreamers ? 'message.ERR_DREAMER_1003' : null
      })
          
      if(this.selected.length > 0){
        opt.push(
          {
            action: "customizeDreamers",
            icon: "SlidersIcon",
            name: this.$t('customize.customize'),
          }, 
          {
            action: "contentRestriction",
            icon: "ClockIcon",
            name: this.$t('customize.mandatory'),
          }, 
          {
            action: "reassignGroup",
            icon: "LogInIcon",
            name: this.$t('customize.change_group')
          },
          {
            action: 'assignPasswords',
            name: this.$t('children_passwords.edit_children_passwords'),
            icon: "UnlockIcon"
          },)
      }
      let del_opt = {
          action: "dropdown",
          icon: "Trash2Icon",
          variant: "danger",
          name: this.$t('forms.delete'),
          dropdown: [
            {
              action: "deleteByExcel",
              icon: "FileMinusIcon",
              name: this.$t('delete_wizard.title'),
              variant: "danger",
            }
          ],
      }

      if(this.selected.length == 0){
        del_opt.dropdown.push({
              action: "deleteAll",
              icon: "AlertOctagonIcon",
              name: this.$t('forms.delete_all.dreamers'),
              variant: "danger",
            })
      }
      
      if(this.selected.length > 0){
        del_opt.dropdown.unshift({
              action: "deleteSelection",
              icon: "Trash2Icon",
              name: this.$t('table.deactivate_selection', {model: this.$t('models.dreamers')}),
              variant: "danger",
            })
      }

      opt.push(del_opt)

      return opt
    },
  },
  methods: {
    hideLPSidebar() {
      this.showSidebar = false
    },
    showLPSidebar(id){
      this.sidebar_lp_dreamer_id = id
      this.showSidebar = true
    },
    //delete all
    checkFormValidity() {
      const form_valid = this.$refs.deleteallform.checkValidity()

      let valid_text = false
      if(form_valid){
        if(this.confirmation_text == this.stablished_confirmation_text){
          valid_text = true
        }
      }
      this.deleteAllState = valid_text
      return valid_text
    },
    handleOkDeleteAll(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteAll()
    },
    handleDeleteAll() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      
      let sel = this.dreamers.map(item => item.id)
      let data = {
        dreamers:sel,
      }

      this.$http.post(deleteAllSchoolDreamer, data).then( response =>{
        if(response.data.status == 200){
          this.$refs.filter.search();
          this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));
        } else {
          this.makeToast('danger',this.$t('Error'), this.$t('forms.delete_all.error'));
        }
      }).catch(error => {
        // console.log("Err -> ", error)
          this.makeToast('danger',this.$t('Error'), error);
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['delete-all'].toggle('#toggle-btn')
      })
    },
    showModalDeleteAll(){
      this.$bvModal.show('delete-all')
    },
    //move group
    checkReassignFormValidity() {
      const valid = this.$refs.reassignform.checkValidity()
      this.reassignState = valid
      return valid
    },
    resetModal() {
      this.group_to_reassign = ''
      this.reassignState = null

      this.confirmation_text = ''
      this.deleteAllState = null
    },
    handleOkReassignGroup(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleReassignGroup()
    },
    handleReassignGroup() {
      // Exit when the form isn't valid
      if (!this.checkReassignFormValidity()) {
        return
      }
      // AXIOS
      let sel = this.selected.map(item => item.id)
      let data = {
        dreamers:sel,
        group_id:this.group_to_reassign.id
      }

      this.$http.post(schoolDreamerReasign, data).then( response =>{
        if(response.data.status == 200){
          this.$refs.filter.search();
          this.makeToast('success', this.$t('Success'), this.$t('forms.reassign_group_success'));
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('forms.reassign_group_error'));
        }
        this.selected= []
      }).catch(error => {
        // console.log("Err -> ", error)
          this.makeToast('danger', this.$t('Error'), error);
      })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['reassign-modal'].toggle('#toggle-btn')
      })
    },
    showModalReassignGroup(){
      this.$bvModal.show('reassign-modal')
    },
    manageSelectedRows(selected_rows){
      this.selected = selected_rows
    },
    deleteSelection(){
        this.$bvModal
          .msgBoxConfirm(this.$t('table.deactivate_dreamers_recover')+' '+this.$t('table.delete_selection_confirmation', {num:this.selected.length}), {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let sel = this.selected.map(item => item.id)
              let data = {
                'dreamers':   sel
              }
              this.$http.post(deleteSelectedSchoolDreamer, data).then( response =>{
                if(response.data.status == 200){
                  this.$refs.filter.search();

                  this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                }
                this.selected= []

              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
      
    },
    deleteModel(id){
      this.$bvModal
        .msgBoxConfirm(this.$t('table.deactivate_dreamers_recover')+' '+this.$t('table.deactivate_one_confirmation'), {
          title: this.$t('table.deactivate'),
            size: 'sm',
            okVariant: 'danger',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'id': id
              }
              this.$http.post(deleteSchoolDreamer, data).then( response =>{
                if(response.data.status == 200){
                  this.$refs.filter.search();
                  this.makeToast('success', this.$t('Success'), this.$t('table.deactivate_success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('table.deactivate_error'));
                }
                this.selected = []
              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
    },
    recoverModel(id){
        this.$bvModal
          .msgBoxConfirm(this.$t('forms.recover_confirmation'), {
            title: this.$t('forms.recover'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'id': id
              }
              this.$http.post(recoverSchoolDreamer, data).then( response =>{
                if(response.data.status == 200){
                  this.$refs.filter.search();

                  this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.recover_error'));
                }

              }).catch(error => {
                // console.log("Err -> ", error)
              })
            }
          })
    },
    setloadingData(state){
        this.loading_data = state
    },
    setData(data){
        if(data.dreamers.length > 40000){
            this.dreamers = []
            this.fetch_message = this.$t('table.too_many_records')
            this.loading_data = false
            //this.dreamers.length = 0
        } else {
            this.fetch_message = ''
            this.dreamers = data.dreamers;
            this.loading_data = false
            //this.dreamers.length = data.dreamers_length
        }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    deletedLicenses(message){
      this.showDeleteByExcel = false
      this.makeToast('success', this.$t('Success'), this.$t(message));
    },
    async getData(){
      this.loading_data = true
        let data = {
          school_id : this.$route.params.id ?? null
        }
        if(this.$store.getters['user/getRole'] == 'school-admin'){
          data.school_id = this.$store.getters['user/isSchool'].id
        }
        await this.$http.post(getSchoolDreamers,data).then( response =>{
          this.dreamers = response.data.dreamers.filter(d => !d.deleted_at);
          this.groups = response.data.groups;
          this.users = response.data.users;
          this.school = response.data.school

          this.school_admin_max_children = response.data.max_children

          //GROUPS
          let newGroupsOptions = []
          response.data.groups.forEach(x => {
            let can_select = true;
            newGroupsOptions.push(
                { 'value': x.id, 'label': x.name, 'can_select': can_select }
            ) 
          });
          this.groupsOptions = newGroupsOptions;

          this.loading_data = false
          //this.setData(response);
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>