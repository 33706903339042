<template>
  <div>
    <modal-content-data
      v-show="isContentModalVisible"
      :selected_data="contentModal"
      :report_lang="report_lang"
      :dreamer="dreamerId"
      @close-modal-contents-data="isContentModalVisible = false"
    />
    <div class="summary-header">
      <div class="bloque-inline">
        <h3 style="margin-top: 0.5rem;"> 
          {{ $t('assigned_rutes') }}
        </h3>
      </div>                
    </div>
    <template v-if="loading">
      <div class="text-center mt-2 mb-2">
        <b-spinner variant="primary" label="Text Centered" />
      </div>
    </template>
    <template v-else>
      <app-collapse v-if="this.learning_paths_assigned && this.learning_paths_assigned.length > 0">
        <app-collapse-item v-for="(path, index) in this.learning_paths_assigned" :key="index" title="">
          <template v-slot:header>
            <div class="d-flex">
              <div class="d-flex flex-column align-items-center">
                <img class="ml-05" :src="require(`@/assets/images/learning_paths/${path.img}.png`)" style="width: 30px; height: 30px;">
              </div>
              <div class="d-flex flex-column ml-2">
                <h4 class="mt-05" title="">{{ path.name[lang] }}</h4>
                <span v-if="path.start_date || path.end_date" style="font-size:11px" class="text-muted">
                  {{ path.start_date ? new Date(path.start_date).toLocaleDateString(geti18n) : null }}
                  <span v-if="path.start_date && path.end_date"> - </span>
                  {{ path.end_date ? new Date(path.end_date).toLocaleDateString(geti18n) : null }}
                </span>
              </div>
            </div>
          </template>
          <b-list-group>
            <b-list-group-item v-for="(content, contentIndex) in path.contents" :key="`content-${contentIndex}`">
              <span class="d-flex justify-content-between align-center">
                <span class="d-flex justify-start align-center">
                  <img :src="content.icon_url" alt="Imagen del contenido" style="width: 20px; height: 20px;">
                  <p class="ml-05 mt-1">{{ content.title[lang] }}</p>
                </span>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon"
                  @click="openModal(content.id); $emit('hide')"
                >
                  <feather-icon
                    class="m-0"
                    icon="InfoIcon"
                  />
                </b-button>
              </span>
            </b-list-group-item>
          </b-list-group>
        </app-collapse-item>
      </app-collapse>
      <p v-else class="ml-1 mt-1">{{ $t('learning_path.no_rutes') }}</p>
    </template>
  </div>
</template>

<script>
import { BSpinner, BRow, BCol, BMedia, BButton, BImg, BCardText, BListGroup, BListGroupItem } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { dreamerLPAsignation } from '@/api/learningpaths'
import ModalContentData from '@/views/modals/ModalContentData.vue'
import { searchDreamers } from '@/api/routes'

export default {
  components: {
    BSpinner, 
    BMedia, BButton,
    BImg,
    BCardText,
    BListGroup, BListGroupItem,
    BRow, BCol, 
    AppCollapse,
    AppCollapseItem, ModalContentData
  },
  props: {
    report_lang:{
      type: String,
      required: false,
      default: ''
    },
    dreamerId: {
      type: [Number, String],
      required: false
    }
  },
  data(){
    return {
      loading: false,
      learning_paths_assigned: [],
      contentModal: null,
      isContentModalVisible: false,
      dreamerId: null,
    }
  },
    watch:{
      lang(newLang, oldLang){  /* lanzar la llamada cada vez que cambie el idioma del usuario! */
        this.getData()
      }
    },
  computed: {
    geti18n(){
      return this.$store.getters['user/geti18n']
    },
    lang(){
        return this.$store.getters['user/getLang']
        
      },
  },
  mounted() {
    if(this.dreamerId){
      this.getData(this.dreamerId);
    } else {
      this.getData(this.$route.params.id);
    }
    // console.log(this.learning_paths_assigned)
  },
  methods:{
    openModal(content_id) {
      this.contentModal = content_id;
      this.isContentModalVisible = true;
      this.$bvModal.show('modal-contents-data')
    },
    async getData(dreamerId) {
      this.loading = true;
      let data = {
        'dreamer_id': dreamerId,
        'user_id': this.$store.getters['user/getUserData'].id,
        'token': this.$store.getters['user/getUserData'].sso,
      };
      try {
        const response = await this.$http.post(dreamerLPAsignation, data);
        this.learning_paths_assigned = response.data.learning_paths_assigned;
        this.loading = false;

      } catch (error) {
        this.loading = false;
        // console.log("Err -> ", error);
      }
      data = {
        "age_range": null,
        "group": null,
        "nee": null,
        "searchword": ""
      };
      await this.$http.post(searchDreamers, data).then(response => {
        let dreamers = response.data.dreamers;
        let dreamer = dreamers.find(d => d.owner === true);
        if (dreamer) {
          this.dreamerId = dreamer.id;
        } else {
          dreamer = dreamers.find(d => d.owner === false);
          if (dreamer) {
            this.dreamerId = dreamer.id;
          } else {
            this.dreamerId = null;
          }
        }
      }).catch(error => {
        console.log(error)
      })

    },
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/scss/app-learning-paths.scss";
 #sidebar-right{
  width: 40vw;
 }
.no-bg{
  background: none;
}
.bloque-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.summary-header{
    height: 70px;
    color: #3a8cb5;
    padding: 10px;
}
.ml-05 {
  margin-left: 0.5rem;
}
.mt-05 {
  margin-top: 0.5rem;
}


</style>