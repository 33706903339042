<template>
  <div>
    <b-table sticky-header="true" :no-border-collapse="true" striped responsive class="custom-table" :per-page="perPage"
      :current-page="localCurrentPage" :items="itemsData" :fields="fieldsData">
      <template #cell(status)="data">
        <feather-icon v-if="data.item.status == 'school'" icon="AlertTriangleIcon" stroke="red" />
        <feather-icon v-if="data.item.status == 'exists'" icon="FrownIcon" stroke="red" />
        <feather-icon v-if="data.item.status == 'student'" icon="UserXIcon" stroke="red" />
        <feather-icon v-if="data.item.status == 'ok'" icon="CheckSquareIcon" stroke="green" />
      </template>
      <template #cell(msg_code)="data">
        {{ $t(data.item.msg_code) }}
      </template>
    </b-table>
    <b-pagination v-model="localCurrentPage" :total-rows="itemsData.length" :per-page="perPage" align="center" />
  </div>
</template>
  
<script>
import {
  BBadge,
  BButton,
  BListGroup,
  BListGroupItem,
  BPagination,
  BPopover,
  BTable,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BBadge,
    BButton,
    BListGroup,
    BListGroupItem,
    BPagination,
    BPopover,
    BTable,
    VBPopover,
  },
  props: {
    itemsData: {
      type: Array,
      required: true
    },
    fieldsData: {
      type: Array,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localCurrentPage: this.currentPage,
    };
  },
  watch: {
    currentPage(newVal) {
      this.localCurrentPage = newVal;
    },
  },
  methods: {
    changePage(page) {
      this.localCurrentPage = page;
      this.$emit('update:currentPage', this.localCurrentPage);
    },
  },
  directives: {
    Ripple,
  },
}
</script>
  
<style></style>