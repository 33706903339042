<template>
  <b-card>
    <b-row> <!-- INSTRUCTIONS -->
      <b-col cols="12" class="mb-2" v-if="!isSuccess || !isFailed"> <!-- Texto explicativo inicial -->
        <h5>{{ $t('delete_wizard.title') }}</h5>
        <b-card-text class="text-muted">
          <ol>
            <li>
              {{ $t('delete_wizard.step1') }}
              <b-link @click="exportExample" target="_blank">{{ $t('delete_wizard.here') }}</b-link>
            </li>
            <li>{{ $t('delete_wizard.step2') }}</li>
            <li>{{ $t('delete_wizard.step3') }}</li>
            <li>{{ $t('delete_wizard.step4') }}</li>
          </ol>
        </b-card-text>
        <b-card-text class="text-muted"> {{ $t('delete_wizard.wait') }}</b-card-text>
      </b-col>
    </b-row>
    <b-row> <!-- UPLOAD DROPBOX -->
      <b-col md="12">
        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving"> <!-- INITIAL -->
          <div class="dropbox">
            <input type="file" name="fileLogo" :disabled="isSaving"
              @change="filesChange($event.target.files); fileCount = $event.target.files.length" accept=".xlsx, .xls"
              class="input-file">
            <p v-if="isInitial">
              {{ $t('bulk.tab_upload.upload_placeholder') }}
            </p>
            <p v-if="isSaving">
              <b-spinner label="Loading..." />
              <br>
              <br>
              {{ $t('bulk.tab_upload.upload_loading') }}
            </p>
          </div>
        </form>
        <div v-if="isValidated"> <!-- VALIDATED -->
          <delete-by-excel-table :itemsData="validatedEmails.data" :fieldsData="tableFields" :perPage="5"
            :current-page="1" />
        </div>
        <div v-if="isFailed" style="text-align: center"> <!--FAILED-->
          <h3 class="mt-1 mb-0.5">
            <feather-icon icon="XCircleIcon" class="uploadFailed" />
            {{ $t('bulk.dreamers.error') }}
          </h3>
        </div>
      </b-col>
    </b-row>
    <b-row> <!-- BUTTONS -->
      <b-col md="12" align="center">
        <b-button variant="outline-secondary" class="mt-1 mr-1" type="button"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="$emit('close')">
          {{ $t('import_wizard.go_back_to_list') }}
        </b-button>
        <b-button variant="danger" class="mt-1" type="button" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="deleteLicences" :disabled="!isValidated" v-if="!isSuccess || !isFailed">
          {{ $t('forms.delete') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { validateDreamersDeleteLicenses, dreamerDeleteLicensesPlantilla, deleteDreamersLicenses } from '@/api/routes'
import DeleteByExcelTable from '@/views/schools/dreamers/bulkDelete/DeleteByExcelTable.vue'
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BListGroup,
  BListGroupItem,
  BRow,
  BSpinner,
} from 'bootstrap-vue'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_VAlIDATED = 4;

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormInvalidFeedback,
    BLink,
    BListGroup,
    BListGroupItem,
    BRow,
    BSpinner,

    DeleteByExcelTable
  },
  props: {
    school_id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      file: null,
      showToast: false,
      toastTitle: '',
      toastMessage: '',
      toastVariant: null,
      successMessage: '',

      currentStatus: null,
      validatedEmails: null,
      tableFields: [
        { key: 'status', label: '', sortable: false },
        { key: 'email', label: this.$t('bulk.table.email'), sortable: true },
        { key: 'msg_code', label: this.$t('bulk.table.status'), sortable: true },
      ],
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    isValidated() {
      return this.currentStatus === STATUS_VAlIDATED;
    },
  },
  mounted() {
    this.currentStatus = STATUS_INITIAL;
  },
  methods: {
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    //upload file
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    async filesChange(fileList) {
      if (fileList.length != 1) {
        this.makeToast('danger', this.$t('Error'), this.$t('raices.unique_doc'));
        return
      }
      const formData = new FormData();
      formData.append('school_id', this.school_id);
      formData.append('file', fileList[0]);
      this.uploadedFiles = [];
      this.uploadError = null;
      this.currentStatus = STATUS_SAVING

      await this.$http.post(validateDreamersDeleteLicenses, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        if (response.data.status == 200) {
          this.validatedEmails = response.data
          this.makeToast('info', this.$t('raices.validate_information'), this.$t(response.data.msg_code));
          this.reset()
          this.currentStatus = STATUS_VAlIDATED;
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg_code ? this.$t(response.data.msg_code) : response.data.msg);
          this.reset()
          this.currentStatus = STATUS_FAILED;
        }
      }).catch(err => {
        this.reset()
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        this.currentStatus = STATUS_FAILED;
      });
    },

    async deleteLicences() {
      let verifiedEmails = this.validatedEmails.data.filter(item => item.status === 'ok');
      let data = {
        'emails': verifiedEmails.map(obj => obj.email),
        'school_id': this.school_id
      }
      await this.$http.post(deleteDreamersLicenses, data).then(response => {
        if (response.data.status == 200) {
          this.reset()
          this.currentStatus = STATUS_SUCCESS;
          this.$emit('deletedLicenses', response.data.msg_code);
          this.$emit('close');
        } else {
          this.makeToast('danger', this.$t('Error'), response.data.msg_code ? this.$t(response.data.msg_code) : response.data.msg);
          this.reset()
          this.currentStatus = STATUS_FAILED;
        }
      }).catch(err => {
        this.reset()
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        this.currentStatus = STATUS_FAILED;
      });
    },

    //export example
    async exportExample() {
      let data = {
        school_id: this.school_id,
      }
      await this.$http.post(dreamerDeleteLicensesPlantilla, data, { responseType: 'blob' }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'example.xlsx'); //@TODO translate
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })
    },
  },
};
</script>